<template>
	<div>
		<div id="scrollbar">
            <div id="scrollbar-bg"></div>
        </div>
		<header class="header_white">
			<Navbar logoColor="true"></Navbar>
		</header>
		<section id="about" class="bg_lighgrey_pattern">
			<div id="post" class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 aos-init" data-aos="fade-up" data-aos-duration="1000">
            			<img class="img-fluid img--animated-soft" :src="require(`@/assets/blog/Nemanja-Rakicevic-Serbian-Researchers.png`)" alt="blog" height="" width="">
						<h3 class="title_xl title_lightblue mt-4 mb-3">
							Nemanja Rakićević: Želimo da stvorimo snažnu mrežu srpskih naučnika koja aktivno doprinosi globalnoj naučnoj zajednici!
						</h3>

            <div class="text_bold text_m mt-4 mb-4">
              <div class="row px-3">
                <div class="d-inline-block mr-3">
                  <img class="rounded-circle" :src="require(`@/assets/blog/milica.jpeg`)" width="40" height="40">
                </div>
                <div class="d-inline-block">
                  Milica Brković<br>
                  26. mart 2024
                </div>
              </div>
            </div>
						<div class="reading-time badge badge-danger d-inline-block px-2">
							<details>
							  <summary>1 minut</summary><span></span>
							</details>
						</div>
            <hr/>
						<p class="text_xl  my-3">
							Promocija naučnih dostignuća i umrežavanje istraživača predstavljaju važan temelj za napredak svake naučne zajednice.
							Platforma Serbian Researchers osmišljena je kao centralno mesto za naučnike koji žele da uspostave saradnju na međunarodnim projektima, pronađu mentore ili kolege za istraživačke projekte, kao i za one u ranim fazama karijere koji traže savete ili prilike za profesionalni razvoj.
							<br><br>
							U trećem intervjuu sa osnivačem Nemanjom Rakićevićem otkrivamo na koji način Serbian Researchers doprinosi promociji naučnih rezultata i većoj vidljivosti srpskih istraživača, kao i njihovom umrežavanju sa međunarodnom akademskom zajednicom.
						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							1. Kada ste sa Velimirom i Lukom prvi put pričali o ideji za razvoj platforme Serbian Researchers, šta Vas je motivisalo da započnete ovaj projekat?
						</h3>
						<p class="text_xl  my-3">
							O stvaranju platforme Serbian Researchers smo prvi put razgovarali 2020. godine, kada nam je Luka predstavio ideju o platformi za povezivanje naših istraživača iz različitih domena nauke, akademskih institucija i industrije, kako unutar Srbije tako i van njenih granica povezujući našu naučnu emigraciju. Ovakva platforma nije postojala i imala je mnogo smisla, jer je od izuzetne važnosti istraživačima u različitim fazama karijere - studentima koji traže master i doktorske studije ili stručne prakse, mladim istraživačima koji traže saradnike sa drugih institucija za međunarodne projekte, kao i starijim profesorima ili preduzećima koji žele da zaposle mlađe istraživače u svojim timovima.
							<br><br>
							Meni lično bi ovakva platforma bila neizmerno korisna da je postojala pre mojih master studija, jer bi mi značajno olakšala proces traženja master programa i kasnije mentora i grupu za doktorske studije.
							<br><br>
							Ubrzo nakon sastanka sa Lukom smo imali nekoliko inicijalnih sastanaka sva trojica zajedno da definišemo misiju, viziju i korake ka operativnoj realizaciji platforme. Platformu smo zvanično javno predstavili na leto 2021. i od tada imamo preko 300 registrovanih članova.
							<br><br>
							Nadamo se da će u budućnosti ovaj broj nastaviti da raste i da će platforma postati korisnija za našu istraživačku zajednicu, jer planiramo da pojačamo angažovanje članova kroz dodatne funkcionalnosti platforme, ali i organizaciju događaja čiji će cilj biti jačanje lokalne zajednice.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							2. Koje ključne prednosti Serbian Researchers donosi naučnicima iz Srbije i kako može doprineti jačanju njihove vidljivosti i saradnje sa industrijom?
						</h3>
						<p class="text_xl  my-3">
							Glavnu korist koju SR ima za naučnike u Srbiji vidim u kontekstu povezivanja i saradnje na međunarodnim projektima. Neki od konkursa za evropske projekte zahtevaju da kandidati budu konzorcijum od nekoliko institucija iz različitih zemalja, a ponekad i iz zemalja koje su van EU. U ovakvim situacijama, platforma bi značajno olakšala pronalaženje potencijalnih saradnika u zemljama gde istraživači nemaju kontakte ili nisu upoznati sa radom drugih istraživača.
							<br><br>
							Pored toga, mnogi naši istraživači u inostranstvu žele da se vrate u Srbiju i nastave svoj istraživački rad, bilo u akademiji ili u industriji, tako da kroz platformu mogu lakše da nađu relevantne kontakte. Dodatno, i naše firme koje su okrenute ka razvoju i traže kadrove za R&D timove mogu lakše privući naše istraživače iz inostranstva koji možda planiraju da se vrate u nekom trenutku i nastave život u Srbiji.
							<br><br>
							U narednom periodu planiramo da pokrenemo nekoliko inicijativa u sklopu Serbian Researchers kako bismo posvetili više pažnje našim članovima i njihovom radu.
							Imamo puno briljantnih istraživača za koje naša javnost nije nužno čula, tako da bi promovisanje njihovog rada bilo na obostranu korist. Takođe, svesni smo koliko može biti teško uklopiti se u nove sredine nakon selidbe zbog posla.
							<br><br>
							Zbog toga želimo da organizujemo lokalne događaje za naše članove kako bismo ojačali našu istraživačku zajednicu širom sveta. Naš cilj je da svaki novi član, po dolasku u novo okruženje, lako pronađe kontakte u okviru SR zajednice koji mu mogu pružiti podršku i olakšati proces prilagođavanja.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							3. Na koji način Serbian Researchers može da doprinese boljoj vidljivosti i povezivanju srpskih naučnika sa međunarodnom akademskom zajednicom? Kako vidite njen dugoročni uticaj na naučnu zajednicu u Srbiji i dijaspori?
						</h3>
						<p class="text_xl  my-3">
							Naša vizija je da Serbian Researchers postane referentna platforma za povezivanje sa srpskom naučnom zajednicom. Ciljna publika prvenstveno obuhvata osobe koje se aktivno bave naučnim radom, ali i sve zainteresovane za nauku i rad naših istraživača, kako u Srbiji tako i širom sveta.
							<br><br>
							Uvek smo otvoreni za saradnju i rado prihvatamo sugestije od članova i šire naučne zajednice, jer smatramo da će nam korisni saveti pomoći za dalje unapređenje funkcionalnosti i pogodnosti same platforme.
							<br><br>
							Takođe, planiramo da se više fokusiramo i na organizovanje događaja, uživo i virtuelno, sa različitim ciljevima – kako neformalnih za upoznavanje i jačanje lokalnih zajednica, tako i edukativnih radionica za razmenu i širenje znanja i istraživačkih rezultata naših istraživača. Kroz radionice, istaknuti naučnici iz Srbije i dijaspore, kao i strani eksperti, mogu deliti svoja iskustva, ekspertizu i savete o ključnim temama u nauci i o karijernom putu.
							<br><br>
							Dugoročno, ova inicijativa može da stvori snažnu mrežu srpskih naučnika koji aktivno doprinose globalnoj naučnoj zajednici, što će, nadamo se, rezultirati većim brojem naučnih publikacija, projekata i inovacija sa međunarodnim uticajem.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							4. Da li postoje planovi za širenje platforme na regionalni ili međunarodni nivo i kako zamišljate održivost platforme na duže staze? Koji bi bili potencijalni modeli finansiranja?
						</h3>
						<p class="text_xl  my-3">
							Platforma Serbian Researchers ima ambiciju da gradi saradnje na regionalnom i međunarodnom nivou kroz povezivanje sa srodnim organizacijama slične misije iz susednih zemalja i šire. Planovi uključuju uspostavljanje saradnje sa inicijativama poput Asocijacije za napredak nauke i tehnologije iz Bosne i Hercegovine i MAIA (Montenegrin AI Association) iz Crne Gore, kao i sličnim regionalnim inicijativama na Balkanu koje dele zajednički cilj promocije nauke i inovacija i povezivanja istraživača.
							<br><br>
							Pored povezivanja, cilj je proširiti fokus od trenutnog nukleusa Serbian Researchers ka širim ciljnim grupama kroz kreiranje sadržaja koji će doprineti boljoj vidljivosti istraživača iz celog regiona. Povezivanjem i zajedničkim projektima, platforma može postati centralno mesto za naučno povezivanje i saradnju na Balkanu.
							<br><br>
							Trenutno, održavanje platforme finansiramo iz naših ličnih sredstava i bavimo se operativnim radom u svoje slobodno vreme, što nije održivo s obzirom na buduće planove. Održivost platforme na duže staze planira se kroz kombinaciju grantova, sponzorstava i saradnje sa akademskim i industrijskim partnerima.
							<br><br>
							Takođe, planiramo pokretanje pilot projekata kako bismo privukli dodatna sredstva, uključujući zajedničke istraživačke projekte i obuke za mlade istraživače. Idealna situacija bi bila kada bismo mogli da finansijski podržimo takve inicijative i, u nekom trenutku, potencijalno dodeljujemo grantove i stipendije.
							<br><br>
							Stoga, otvoreni smo za saradnju ukoliko neko iz industrije ili drugih srodnih institucija želi da finansijski i logistički podrži platformu, dok god ostajemo konzistentni sa našom inicijalnom misijom i vrednostima – da smo nezavisna, apolitična i neprofitabilna platforma za povezivanje istraživača i promociju nauke.
						</p>
					</div>

				</div>
			</div>
		</section>
		<Footer bg="footer_bg-red"></Footer>
	</div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

export default{
	name: "PlatformaSrpskiIstrazivaci",

	components: {
		Navbar,
		Footer
	},
	methods: {
		getWordCount(){
			const post = document.getElementById("post");
			return post.innerText.match(/\w+/g).length;
		},

		setReadingTime(){
			const avgWordsPerMin = 250;
			const readingTimeSummary = document.querySelector(".reading-time");

			let count = this.getWordCount();
			let time = Math.ceil(count / avgWordsPerMin);

			readingTimeSummary.innerText = time + " min čitanja";
		}
	},

  	mounted() {

		this.setReadingTime();

      	var bar_bg = document.getElementById("scrollbar-bg"),
			body = document.body,
			html = document.documentElement;

		bar_bg.style.minWidth = document.width + "px";

		document.getElementsByTagName("body")[0].onresize = function() {
			// Update the gradient width
			bar_bg.style.minWidth = document.width + "px";
		}

		window.onscroll = function() {
			// Change the width of the progress bar
			var bar = document.getElementById("scrollbar"),
				dw  = document.documentElement.clientWidth,
				dh  = Math.max( body.scrollHeight, body.offsetHeight,
							html.clientHeight, html.scrollHeight, html.offsetHeight ),
				wh  = window.innerHeight,
				pos = pageYOffset || (document.documentElement.clientHeight ?
								document.documentElement.scrollTop : document.body.scrollTop),
				bw  = ((pos / (dh - wh)) * 100);

			bar.style.width = bw + "%";
		}
  	}
}

</script>
